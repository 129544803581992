<template>
  <div v-if="showGroup" v-frag>
    <v-card
      v-if="item.title || item.total"
      :outlined="level > 1"
      class="gw-report__card mb-2"
    >
      <template v-if="item.title">
        <v-card-title v-if="level === 1">
          <button
            v-if="isExpandable"
            :class="{ active: bExpand }"
            class="d-flex w-full justify-space-between"
            type="button"
            @click="toggle"
          >
            <div v-html="item.title" />
            <v-icon :color="bExpand ? 'primary' : 'gray'">
              mdi-chevron-down
            </v-icon>
          </button>
          <div v-else v-html="item.title" />
        </v-card-title>
        <v-card-subtitle v-else v-text="item.title" />
        <v-divider />
      </template>

      <v-card-title v-if="isRoot">
        <slot name="top" />
      </v-card-title>

      <v-expand-transition>
        <v-card-text v-if="bExpand">
          <template v-if="showTable">
            <v-data-table
              :headers="headers"
              :hide-default-footer="items.length <= 10"
              :items="items"
              :items-per-page="10"
              class="dt-listing"
              dense
              disable-sort
            >
              <template #[`item.${sOrderNumberHeader}`]="{ item }">
                <invoice-number-btn :invoice="item" />
              </template>
            </v-data-table>
          </template>
          <report-group
            v-for="(obItem, index) in items"
            v-else
            :key="`report-${level}-${obItem.id}-${index}`"
            :headers="headers"
            :hide-content="hideContent"
            :item="obItem"
            :level="level + 1"
          />
        </v-card-text>
      </v-expand-transition>

      <v-card-actions v-if="totals" class="blue-grey lighten-5 py-0">
        <v-spacer />
        <v-list
          class="pa-0"
          color="transparent"
          dense
          max-width="100%"
          min-width="200"
        >
          <v-list-item
            v-for="(total, label) in totals"
            :key="`report-${item.group}-${level}-${label}-total`"
          >
            <v-list-item-subtitle v-text="label" />
            <v-list-item-title
              class="text-right font-weight-bold"
              v-text="total"
            />
          </v-list-item>
        </v-list>
      </v-card-actions>
    </v-card>

    <report-group
      v-for="(obItem, index) in items"
      v-else
      :key="`report-root-${item.group}-${index}`"
      :headers="headers"
      :hide-content="hideContent"
      :item="obItem"
      :level="level + 1"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import type { ReportItems } from "@/types/utils";
import Sheet from "@/components/common/Sheet.vue";
import { get, isObjectLike } from "lodash";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";

@Component({
  name: "ReportGroup",
  components: { InvoiceNumberBtn, Sheet, ReportGroup },
})
export default class ReportGroup extends Vue {
  @VModel({ type: Boolean, default: true }) bExpanded!: boolean;
  @Prop(Object) readonly item!: ReportItems;
  @Prop([Array, Object]) readonly headers!: DataTableHeader[];
  @Prop({ type: [Number, String], default: 0 }) readonly level!: number;
  @Prop(Boolean) readonly collapsed!: boolean;
  @Prop(Boolean) readonly hideContent!: boolean;

  bExpand = true;
  bExpandChild = true;

  get isRoot() {
    return !this.level || this.level === 0;
  }

  get isFloor(): boolean {
    return !!this.items.length && !get(this.items, "0.items");
  }

  get isExpandable() {
    return !(
      this.hideContent &&
      !!this.items.length &&
      !get(this.items, "0.items.0.items")
    );
  }

  get items() {
    return !this.item || !this.item.items || !this.item.items.length
      ? []
      : this.item.items;
  }

  get showTable(): boolean {
    return this.items.length > 0 && !get(this.items, "0.items");
  }

  get showGroup() {
    if (this.hideContent && this.isFloor) {
      return false;
    }

    return true;
  }

  get totals() {
    if (!this.item.total) {
      return null;
    }

    if (isObjectLike(this.item.total)) {
      return this.item.total;
    }

    return {
      [this.$t(this.isRoot ? "total.price" : "subtotal") as string]:
        this.item.total,
    };
  }

  get sOrderNumberHeader() {
    return this.$t("invoice.dgi.number");
  }

  toggle() {
    this.bExpand = !this.bExpand;
    this.emit();
  }

  emit() {
    this.$emit("input", this.bExpand);
  }

  mounted() {
    if (this.collapsed) {
      this.bExpand = false;
      this.bExpandChild = false;
      this.emit();
    }
  }
}
</script>
